import React from "react";
import './logo.scss';
import {Link} from 'gatsby';

/**
 * Logo component
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const Logo = ({text}) => {
    return (
        <Link to="/" className="logo">
            <h1>
                {text}
            </h1>
        </Link>
    );
}
export default Logo;