import React from "react";
import Layout from "../components/Layout/Layout";
import ProjectList from "../components/ProjectList/ProjectList";

/**
 * Homepage template
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Index = (props) => {
    const { pageContext } = props;
    const { layoutData, indexData } = pageContext;
    const { projects } = indexData;

    return <Layout data={layoutData}>
        <ProjectList projects={projects} />
    </Layout>
}

export default Index;