import React from "react";
import './text.scss';

/**
 * Text component
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const Text = ({children}) => {
    return <p className="text">{children}</p>;
}
export default Text;