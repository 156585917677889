import { Col, Row } from "react-flexbox-grid";
import { Link } from "gatsby";
import React from "react";
import './projectList.scss';

const ProjectList = ({ projects }) => {
    return (
        <ul className="project-list">
            {projects.map((project) => {
                return (
                    <li key={project.id}>
                        <Row>
                            <Col lgOffset={3} lg={6}>
                                <Link to={`/project/${project.slug}`}>
                                    <div className="container flip-parent">
                                        <img src={project.thumbnail} alt={project.title} className="image flip" />
                                        <div className="overlay flip">
                                            <div className="linktext">{project.title}</div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </li>
                )
            })}
        </ul>
    )
}

export default ProjectList;