import React from "react"
import './layout.scss';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Logo from "../Logo/Logo";
import Text from "../Text/Text";
import { Link } from 'gatsby';


/**
 * Shared layout
 * @param children
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Layout = ({ children, data }) => {
    const { header } = data;
    return (
        <Grid>
            <Row>

                <Col xs={6} lg={2}>
                    <Logo text="lennart" />
                </Col>
                <Col xs={6} lgOffset={8} lg={2}>
                    <Link to="/info">
                        <p className="align-right">
                            info
            </p>
                    </Link>                </Col>

            </Row>
            <Row>
                <Col lg={12}>
                    {children}
                </Col>
            </Row>

        </Grid>
    )
}
export default Layout
